export const siftsyTheme = {
    typography: {
      fontFamily: [
        "Gilroy",
        'sans-serif',
      ].join(','),
      caption: {
        fontSize: '0.7rem',
        lineHeight: 1.66,
        color: "#222"
      },
      h4: {
        fontSize: '1.3rem',
        fontWeight: 700,
        letterSpacing: "-2px",
        color:"#222",
      },
      h5: {
        fontSize: '1.1rem',
        color: "#212121",
        fontWeight: 900,
      },
      h6: {
        fontSize: '1.2rem',
        fontWeight: 900,
        color: "#212121"
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.66,
      },
      body1: {
        fontSize: '1rem',
        fontFamily: [
          "Gilroy",
          'sans-serif',
        ].join(','),
        color: "#222"
      },
      subtitle1: {
        fontSize: '.9rem',
        fontWeight: 700,
        fontFamily: [
          "Gilroy",
          'sans-serif',
        ].join(','),
        color: "#4828c5",
      },
   
  
    subtitle2: {
      fontSize: '.8rem',
      fontWeight: 600,
      fontFamily: [
        "Gilroy",
        'sans-serif',
      ].join(','),
      color: "#858585",
    },
  
  },
  components:{
    MuiRadio:{
      styleOverrides: {
        root: {
          color: "#000",
          '&.Mui-checked': {
            color: "#000",
          },
          '&.Mui-disabled': {
            color: "#eee",
          },
        },
      },
    },
    MuiLinearProgress:{
      styleOverrides: {
        root: {
          borderRadius: 30,
          height: 10,
          backgroundColor: "#fff",
          border: "1px solid #4828c5",
        },
        bar: {
          borderRadius: 30,
          backgroundColor: "#4828c5",
        },
      },
      
    },
    MuiTooltip:{
      styleOverrides: {
        tooltip: {
          fontSize: "0.9rem",
          borderRadius: 12,
          backgroundColor: "#fff",
          color: "#666", 
          lineHeight: 1.3,
          padding: "15px",
        },
      },
      
    
    },
    MuiTextField:{
      styleOverrides: {
        root:{
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0, // Set the border width to 0
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0, // Set the border width to 0 on hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0, // Set the border width to 0 when focused
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              border: 'none', // Remove the border when focused
            },
            '&:hover fieldset': {
              border: 'none', // Add a border on hover
            },
            '&.Mui-focused': {
              border: 'none', // Add a border on hover
            },
            
            
          },
        
        }
      },
      
    },
  },
  
    palette: {
      primary: {
        main: '#4828c5', // Custom primary color
      },
      secondary: {
        main: '#ffffff', // Custom secondary color
      },
      success: {
        main: '#77ee00', // Custom secondary color
      },
    },

};


