import React from "react";
import { AppBar, Box, ButtonBase } from "@mui/material";
import DemoButton from "./DemoButton";

function SiftsyAppBar() {
  return (

    <AppBar position="static" color="transparent" elevation={0}>
        <Box sx={{width:"100%", margin:"auto"}}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        m:4,
        p:2,
        borderRadius:3,
        boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.1)",
    }}
    >
        <ButtonBase onClick={() => window.location.href = "/"}>
      <img src={"/siftsy_logo.png"} width={80} />
        </ButtonBase>
      <Box sx={{ flexGrow: 1 }} />
      <DemoButton small>
        Book a Demo
      </DemoButton>
    </Box>
    </Box>

  </AppBar>

  );
}

export default SiftsyAppBar;