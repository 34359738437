import React from "react";
import "@fillout/react/style.css"
import { FilloutPopupEmbed } from "@fillout/react";
import SiftsyButton from "./SiftsyButton";

function DemoButton(props){
    const [open, setOpen] = React.useState(false);

    return(
        <>
        <SiftsyButton {...props} color="primary" variant="contained" onClick={() => setOpen(true)}>
        Book a Demo
      </SiftsyButton>
      {open && (
        <FilloutPopupEmbed
          filloutId="jC2n4LiNsQus"
          onClose={() => setOpen(false)}
        />
      )}
        </>
    )
}

export default DemoButton;