import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const SiftsyButton = styled(Button)(({ theme, small }) => ({
    background: "#4728c4",
    border: 0,
    borderRadius: 30,
    color: "white",
    padding: small ? "4px 18px" : "6px 28px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: -.5,
    fontWeight: 700,
    fontSize: small ? 16 : 20,
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#4728c4",
    },
  }));

export default SiftsyButton;