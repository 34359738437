import logo from './logo.svg';
import './App.css';
import Landing from './Landing';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import Privacy from './Privacy';
import Terms from './Terms';

function App() {
  return (
    <div className="App">
    <Router>
      
      
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/termsofuse" element={<Terms />} />
        <Route path="/termsofservice" element={<Terms />} />
        <Route path="/" element={<Landing />} />
      </Routes>
      

    </Router>

    </div>
  );
}

export default App;
